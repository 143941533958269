<!-- Link that automatically determines it's type based on the input -->
<template>
  <Component
    :is="type"
    v-bind="linkParams"
    ref="baseLink"
    @click="onClick">
    <slot/>
  </Component>
</template>

<script setup>
import { ref } from 'vue'
import useLink from '@shared/composables/link.js'

const props = defineProps({
  to: {
    type: [String, Object],
    default: null
  }
})

const { type, linkParams } = useLink(props.to)

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})

const baseLink = ref(null)
function onClick (event) {
  baseLink.value.blur?.() // Anchor
  baseLink.value.$el?.blur?.() // RouterLink
  emit('click', event)
}
</script>
