<template>
  <BaseButton @click="onClick"/>
</template>

<script setup>
import useAnalytics from '@shared/composables/analytics.js'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { captureException } from '@sentry/vue'

const props = defineProps({
  appEntryPoint: {
    type: String,
    required: true,
    validator: value => ['homepage', 'website'].includes(value)
  },
  eventLabel: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})

const { trackClickEvent } = useAnalytics()

async function onClick (event) {
  const href = event.currentTarget.getAttribute('href')
  try {
    if (href) {
      // If an href is present, we'll prevent the navigation
      // until we have a chance to track the click event.
      event.preventDefault()
    }
    await trackClickEvent(props.appEntryPoint, props.eventLabel)
    emit('click', event)
  } catch (error) {
    captureException(error)
  } finally {
    if (href) {
      window.location.href = href
    }
  }
}
</script>
