export function serializeParams (params) {
  return Object.keys(params).map(key => {
    const val = params[key]
    if (Array.isArray(val)) {
      if (!val.length) return ''
      return val.map(x => key + '=' + encodeURIComponent(x)).join('&')
    }
    if (val) {
      return key + '=' + encodeURIComponent(val)
    }
    return ''
  }).filter(x => x !== '').join('&')
}

export function filterEmptyParams (params) {
  if (!params) return {}
  return Object.keys(params).reduce((acc, key) => {
    const val = params[key]
    if (Array.isArray(val)) {
      if (val.length) {
        acc[key] = val.filter(x => x !== null)
      }
    } else if (val) {
      acc[key] = val
    }
    return acc
  }, {})
}
