<!--
Headline text with optional link subtitle.
Exposes a button container for teleporting horizontal scroll buttons alongside it.
-->
<template>
  <div class="section-headline text-center">
    <div class="wrapper">
      <h2 class="m-0">
        {{ title }}
      </h2>
      <BaseButton
        v-if="link"
        :text="link.text"
        variant="text-link"
        class="py-xs px-sm"
        @click="$router.push(link.to)"/>
      <div
        v-if="isTabletOrLarger"
        ref="buttonContainer"
        class="button-container column-gap-lg"/>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import BaseButton from '@shared/components/ADORN/BaseButton'
import useScreenSize from '@shared/composables/screenSize.js'

defineProps({
  title: {
    type: String,
    required: true
  },
  link: {
    type: Object,
    default: () => null,
    validator: value => {
      return typeof value === 'object' &&
        typeof value.text === 'string' &&
        typeof value.to === 'object' &&
        ((typeof value.to.name === 'string' && typeof value.to.params === 'object') ||
          typeof value.to.path === 'string')
    }
  }
})

const { isTabletOrLarger } = useScreenSize()

// Expose buttonContainer so that the buttons can be teleported to it.
const buttonContainer = ref(null)
defineExpose({ buttonContainer })
</script>

<style lang="scss" scoped>
$button-size: 32px;
.section-headline {
  font-family: $headings-font-family;
  padding: 20px 0px;

  .wrapper {
    position: relative;

    .base-button {
      height: auto;
    }

    .button-container {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;

      :deep(button) {
        width: $button-size;
        height: $button-size;
        border: 1px solid $ash;
        color: $pewter;
        background-color: $white;
      }
    }
  }
}
</style>
