<!--Clickable preview image for a style color with Quick Actions that tracks when when it's viewed.-->
<template>
  <PreviewImageButton
    ref="previewImageButton"
    class="style-color-preview-image"
    :src="imageSource"
    :size="size"
    :alt="alt"
    :eager-load="eagerLoad"
    @visible="onVisible"
    @loaded="emit('loaded', $event)"
    @click.exact="emit('click', $event)">
    <template #overlay>
      <StyleColorQuickActions
        v-if="isAuthenticated && !discontinued"
        :orientation="actionButtonOrientation"
        :size="quickActionsSize"
        :style-color="styleColor"
        :style-color-source="styleColorSource"
        :style-color-source-index="styleColorSourceIndex"/>
    </template>
  </PreviewImageButton>
</template>

<script setup>
import '@types'
import { computed, ref } from 'vue'
import PreviewImageButton from './PreviewImageButton.vue'
import StyleColorQuickActions from './StyleColorQuickActions.vue'
import { track } from '@/plugins/analytics.js'
import useStyleColor from '@shared/composables/styleColor.js'
import { useResizeObserver } from '@vueuse/core'
import { useClientStore } from '@shared/stores/client.js'
import { storeToRefs } from 'pinia'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  /** @type {PropType<StyleColorSource>} */
  styleColorSource: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: null
  },
  actionButtonOrientation: {
    type: String,
    default: 'vertical',
    validator: value => ['vertical', 'horizontal'].includes(value)
  },
  src: {
    type: String,
    default: null
  },
  size: {
    type: String,
    default: 'auto',
    validator: value => ['auto', 'xx-large', 'x-large', 'large', 'medium'].includes(value)
  },
  eagerLoad: {
    type: Boolean,
    default: false
  },
  disableViewTracking: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits({
  visible: (value) => typeof value === 'boolean',
  loaded: (event) => event instanceof Event,
  click: (event) => event instanceof MouseEvent
})

const { isAuthenticated } = storeToRefs(useClientStore())

const { id, brand, previewImage, discontinued } = useStyleColor(() => props.styleColor)

const imageSource = computed(() => props.src ?? previewImage.value.url)
const shortName = computed(() => {
  switch (props.styleColor.style.subcategory) {
    case 'Soft Pants':
      return 'Casual Pants'
    case 'Soft Jacket':
      return 'Jacket'
    case 'Shirt/Button-up':
      return 'Button-up'
    case 'Other':
      return ''
    default:
      return props.styleColor.style.subcategory
  }
})
const alt = computed(() => `${brand.value} ${shortName.value}`.trim())

// Quick Actions aren't responsive yet so we'll listen to size changes.
const previewImageButton = ref(null)
const quickActionsSize = ref(props.size === 'auto' ? 'medium' : props.size)
if (props.size === 'auto') {
  useResizeObserver(previewImageButton, entries => {
    if (props.size !== 'auto') {
      quickActionsSize.value = props.size
      return
    }
    const { width } = entries[0].contentRect
    if (width <= 72) {
      quickActionsSize.value = 'tiny'
    } else if (width <= 99) {
      quickActionsSize.value = 'small'
    } else if (width <= 120) {
      quickActionsSize.value = 'medium'
    } else if (width <= 153) {
      quickActionsSize.value = 'large'
    } else {
      quickActionsSize.value = 'x-large'
    }
  })
}

function onVisible (visible) {
  trackViewed(visible)
  emit('visible', visible)
}

const viewed = ref(false)
function trackViewed (visible) {
  if (props.disableViewTracking) {
    return
  }
  if (visible && !viewed.value) {
    viewed.value = true
    const { sourceType = null, sourceId = null, sourceName = null, sourceLocation = null, sourceSlot = null, sourcePosition = null } = props.styleColorSource
    track('Viewed Style Color', {
      styleColorId: id.value,
      sourceType,
      sourceId,
      sourceName,
      sourceLocation,
      sourceSlot,
      sourcePosition
    })
  }
}

defineOptions({
  compatConfig: {
    WATCH_ARRAY: false // Suppress warning from useResizeObserver.
  }
})
</script>
