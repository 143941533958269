import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import useVuexModule from '@shared/composables/vuexModule.js'
import { getGlobalFilterOptions, getHomepageSections, getSection, getSectionStyleColors, postStyleColorClick } from '@shared/api/closet.js'
import logger from '@shared/logger.js'
import * as Sentry from '@sentry/vue'
import getKeyedPromise from '@/utils/keyedPromise'
import { until } from '@vueuse/core'
import { useClientStore } from './client'

// TODO: Partial implementation (ENG-2847)
export const useClosetStore = defineStore('closet', () => {
  const { doNothing, ...closet } = useVuexModule('closet')

  const { isAuthenticated } = storeToRefs(useClientStore())

  const baseFilters = ref({
    categories: [],
    occasions: [],
    seasons: [],
    brands: [],
    colors: [],
    patterns: [],
    fabrics: [],
    lengths: [],
    silhouettes: [],
    sleeveLengths: [],
    sleeveTypes: [],
    necklines: [],
    wash: [],
    rise: []
  })
  const globalFilterKeys = ref(['occasions', 'seasons', 'categories'])
  const globalFilters = ref({})
  const globalFiltersLoaded = ref(false)
  const homepageSectionsLoaded = ref(false)
  const ratingsMap = ref({})
  const sale = ref(null)
  const sectionsById = ref({})
  const styleColorsMap = ref({})
  const styleColorSourceIndexes = ref({})
  const styleColorSources = ref({})

  const allSectionsLoaded = computed(() => initialSectionsLoaded.value)
  const homepageSections = computed(() => sections.value.filter(section => section.location === 'homepage'))
  const initialSectionsLoaded = computed(() => homepageSectionsLoaded.value)
  const sections = computed(() => Object.values(sectionsById.value))
  const styleColorsLoading = computed(() => sections.value.some(section => section.styleColorsLoading))

  async function loadInitialData () {
    loadGlobalFilters()
    fetchHomepageSections()
  }
  async function loadGlobalFilters () {
    await fetchGlobalFilterOptions()
    globalFilters.value.selections = globalFilterKeys.value.reduce((acc, key) => {
      acc[key] = []
      return acc
    }, {})
    globalFiltersLoaded.value = true
  }
  function getDefaultSort (section) {
    return section.source === 'autocloset' ? undefined : 'recommended'
  }
  function isGloballyFiltered (section) {
    return ['autocloset', 'style_prefs', 'brand_prefs'].includes(section.source)
  }
  function getBaseFilters (section) {
    return isGloballyFiltered(section)
      ? { ...baseFilters.value, ...globalFilters.value.selections }
      : { ...baseFilters.value }
  }
  function getSectionPayload (section) {
    const sort = getDefaultSort(section)
    const payload = {
      ...section,
      sort,
      filters: getBaseFilters(section),
      detailView: {
        styleColors: null,
        length: null,
        sort
      }
    }
    if (section.source === 'favorites') {
      payload.available = true
      payload.detailView.available = true
    }
    return payload
  }
  function getSectionById (id) {
    return sectionsById.value[id]
  }
  function updateSection (id, { useDetailView = false, ...data }) {
    const section = getSectionById(id)
    if (section.length === null) {
      Object.assign(section, data)
      Object.assign(section.detailView, data)
    } else if (useDetailView) {
      Object.assign(section.detailView, data)
    } else {
      Object.assign(section, data)
    }
  }
  function addStyleColorsToSection (id, styleColors, useDetailView = false) {
    const section = getSectionById(id)
    const styleColorIds = styleColors.map(styleColor => styleColor.id)
    if (!section.styleColors?.length) {
      section.styleColors = styleColorIds
      section.detailView.styleColors = styleColorIds
    } else if (useDetailView) {
      if (!section.detailView.styleColors) {
        section.detailView.styleColors = styleColorIds
      } else {
        section.detailView.styleColors.push(...styleColorIds)
      }
    } else {
      if (!section.styleColors) {
        section.styleColors = styleColorIds
      } else {
        section.styleColors.push(...styleColorIds)
      }
    }
  }
  function updateStyleColorsMap (styleColors) {
    for (const styleColor of styleColors) {
      styleColorsMap.value[styleColor.id] = styleColor
    }
  }
  async function loadStyleColors ({ useDetailView = false, ...data }) {
    const key = `loadStyleColors_${data.id}${useDetailView ? '_detailView' : ''}`
    return getKeyedPromise(key, async (resolve) => {
      const section = getSectionById(data.id)
      const loadStyleColorsRequestId = Math.random()
      if (typeof section === 'undefined') {
        Sentry.captureMessage('loadStyleColors called with invalid section data', {
          level: 'error',
          extra: {
            section: data
          }
        })
        resolve()
        return
      }
      await until(() => section.styleColorsLoading === true).toBe(false)
      try {
        updateSection(section.id, {
          loadStyleColorsRequestId: loadStyleColorsRequestId,
          styleColorsLoading: true
        })
        data.offset = useDetailView
          ? section.detailView.styleColors?.length || 0
          : section.styleColors?.length || 0
        data.available = useDetailView ? section.detailView.available : section.available
        data.sort = useDetailView ? section.detailView.sort : section.sort
        data.sectionFilters = section.filters
        // data.sizeFilter = sizeFilter // TODO? only when authenticated
        const { data: resData } = await getSectionStyleColors(data)

        // Check that this is still the most recent load style colors request for section
        if (loadStyleColorsRequestId === section.loadStyleColorsRequestId || !section.loadStyleColorsRequestId) {
          addStyleColorsToSection(section.id, resData.styleColors, useDetailView)
          updateSection(section.id, {
            length: resData.sectionLength,
            useDetailView
          })

          if (!('filterOptions' in section) || section.filterOptions === null) {
            if (isGloballyFiltered(section)) {
              fetchFilterOptions(section.id)
            } else {
              updateSection(section.id, {
                filterOptions: resData.filterOptions
              })
            }
          }
          updateStyleColorsMap(resData.styleColors)
        }
        resolve()
      } finally {
        updateSection(section.id, {
          styleColorsLoading: false
        })
      }
    })
  }
  async function fetchGlobalFilterOptions () {
    if (isAuthenticated.value) {
      const { data } = await getGlobalFilterOptions()
      globalFilters.value.options = data
    } else {
      globalFilters.value.options = {}
    }
  }
  async function fetchFilterOptions (id) {
    const { data: { filterOptions } } = await getSectionStyleColors({
      id,
      offset: 0,
      amount: 0
    })
    updateSection(id, { filterOptions })
  }
  function updateSectionsByLocation (location, locationSections) {
    for (const { id } of sections.value.filter(section => section.location === location)) {
      delete sectionsById.value[id]
    }
    locationSections.forEach(section => {
      sectionsById.value[section.id] = getSectionPayload(section)
    })
  }
  async function fetchHomepageSections () {
    homepageSectionsLoaded.value = false
    const { data } = await getHomepageSections()
    updateSectionsByLocation('homepage', data.sections)
    homepageSectionsLoaded.value = true
  }
  async function fetchSection ({ id }) {
    const { data: section } = await getSection(id)
    sectionsById.value[id] = getSectionPayload(section)
    section.styleColors?.forEach(styleColor => {
      styleColorsMap.value[styleColor.id] = styleColor
    })
    const amount = Math.min(section.length ?? 100, 100)
    await loadStyleColors({ id, amount, useDetailView: true })
  }
  async function favorite ({ styleColorId }) { doNothing(styleColorId) }
  async function setStyleColorSource ({ styleColorId, source, sourceIndex }) {
    styleColorSources.value[styleColorId] = source
    styleColorSourceIndexes.value[`${styleColorId}-${source.sourceId}`] = sourceIndex
  }
  async function recordStyleColorClick ({ styleColorId, source, sourceIndex }) {
    if (!source) {
      source = styleColorSources.value[styleColorId] ?? null
      sourceIndex = styleColorSourceIndexes.value[`${styleColorId}-${source?.id}`] ?? null
    }
    if (!source) {
      logger.warn('No style color source for click')
    }
    await postStyleColorClick(styleColorId, { source, sourceIndex })
  }

  return {
    allSectionsLoaded,
    baseFilters,
    globalFilters,
    globalFiltersLoaded,
    homepageSections,
    initialSectionsLoaded,
    ratingsMap,
    sale,
    sections,
    sectionsById,
    homepageSectionsLoaded,
    sectionsLoaded: homepageSectionsLoaded,
    styleColorsLoading,
    styleColorsMap,
    loadInitialData,
    getSectionById,
    loadStyleColors,
    getSections: fetchHomepageSections,
    getSection: fetchSection,
    favorite,
    setStyleColorSource,
    recordClick: recordStyleColorClick,
    ...closet
  }
})
