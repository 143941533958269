<template>
  <BaseStickyFooter
    :is-visible="showFooter">
    <template #content>
      <GetStartedButton
        location="Mobile Prospect Footer"
        fixed-width/>
    </template>
  </BaseStickyFooter>
</template>
<script setup>
import BaseStickyFooter from '@shared/components/ADORN/BaseStickyFooter.vue'
import { ref, watch, onUnmounted } from 'vue'
import { useScroll } from '@vueuse/core'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'

const showFooter = ref(true)
const { isScrolling } = useScroll(window, {
  debounce: 500
})

let scrollTimeout = null

watch(isScrolling, (scrolling) => {
  if (scrolling) {
    showFooter.value = false
    clearTimeout(scrollTimeout)
  } else {
    scrollTimeout = setTimeout(() => {
      showFooter.value = true
    }, 1000)
  }
})

onUnmounted(() => {
  clearTimeout(scrollTimeout)
})
</script>
