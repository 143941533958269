import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNavBarStore } from '@shared/stores/navBar.js'

import { useFlyouts } from './flyouts'
import BaseLink from '@shared/components/BaseLink.vue'

export default function useNavigation () {
  const navBar = useNavBarStore()
  const { openMyCaseFlyout, isMyCaseFlyoutOpen } = useFlyouts()
  const router = useRouter()
  const route = useRoute()

  const navItems = computed(() => [
    {
      name: 'Home',
      link: () => { router.push({ name: 'closet-sections' }) },
      active: route.name === 'closet-sections',
      icon: 'SvgHome'
    },
    {
      name: 'Search',
      link: () => { router.push({ name: 'search' }) },
      active: route.name === 'search',
      icon: 'SvgSearch'
    },
    {
      name: 'My Stuff',
      link: () => { router.push({ name: 'my-stuff' }) },
      active: route.name === 'my-stuff',
      icon: 'SvgHeartNewOutline'
    },
    {
      name: 'Community',
      link: () => { router.push({ name: 'feed' }) },
      active: route.name === 'feed',
      icon: 'SvgCommunity'
    },
    {
      name: 'Case',
      link: () => { openMyCaseFlyout() },
      linkType: 'flyout',
      active: isMyCaseFlyoutOpen.value,
      icon: 'MyCaseIcon'
    }
  ])
  const profilePanelItems = computed(() => ([
    { name: 'Style Profile', link: () => { router.push({ name: 'style-profile' }) } },
    { name: 'Rental History', link: () => { router.push({ name: 'profile', params: { tab: 'rental-history' } }) } },
    { name: 'Account', link: () => { router.push({ name: 'account' }) } },
    { name: 'Help + FAQs', link: 'https://support.armoire.style/faq/', linkType: 'external' },
    { name: 'Refer a Friend', link: () => { router.push({ name: 'referral' }) } },
    { name: 'Shop', link: 'https://shop.armoire.style/', linkType: 'external' },
    { name: 'Sign Out', link: logout }
  ]))
  const signedOutNavItems = computed(() => ([
    { name: 'Brands', link: { to: '/#brands' }, linkType: BaseLink },
    { name: 'Plans', link: { to: '/howitworks/#plans' }, linkType: BaseLink },
    { name: 'How It Works', link: { to: '/howitworks/' }, linkType: BaseLink },
    { name: 'FAQ', link: { to: '/frequently-asked-questions/' }, linkType: BaseLink },
    { name: 'Log In', link: { to: '/login/' }, linkType: BaseLink }
  ]))
  const mobileNavItems = computed(() => [
    ...navItems.value.filter(item => item.name !== 'Case')
  ])

  function logout () {
    try {
      if (window.fbInitialized && window.fbAccessToken) {
        // eslint-disable-next-line
        FB.logout(function (response) {})
      }
    } finally {
      window.location.href = '/logout/'
    }
  }

  return {
    navBar,
    navItems,
    profilePanelItems,
    signedOutNavItems,
    mobileNavItems,
    logout
  }
}
